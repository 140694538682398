.v-stretch {
  min-height: 90% !important;
}

.elevated-card {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
  border: none !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pl-1 {
  padding-left: 10px !important;
}

body {
  overflow-x: hidden;
  background-color: #ffff;
}

.no-wrap {
  white-space: nowrap;
}

.text-right {
  text-align: right !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.font-small {
  font-size: 14px;
}

.font-medium {
  font-size: 20px;
}

.font-large {
  font-size: 25px !important;
}

.toggle-sidebar {
  margin-left: 10px;
  height: 35px;
  width: 35px;
  background-color: #2cb34a;
  border: none;
  color: white;
  font-size: 20px;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
}
.toggle-sidebar:active {
  background-color: white;
  color: #2cb34a;
  border: 1px solid #2cb34a;
}

.page-bg {
  /* background-color: #f3f5fa !important; */
  padding-left: 250px !important;
  padding-top: 60px !important;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.navbar.enhanced {
  position: fixed !important;
  right: 0;
  top: 0;
  left: 250px;
  z-index: 100;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.side-nav-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.side-nav-header img {
  height: 50px !important;
}
.side-nav-header .close-sidebar {
  position: absolute;
  width: 45px;
  height: 45px;
  right: -45px;
  border-radius: 0 20px 20px 0;
  background-color: #eb2b2b;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
}

.side-nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 101;
  width: 250px;
}
.side-nav.opened {
  margin-left: 0 !important;
}

.header-search {
  margin-left: 30px;
}
.header-search .form-control {
  border: none;
  background-color: #f3f5fa;
  padding-left: 30px;
}
.header-search .form-control:focus {
  box-shadow: none;
}

.card-stats.card {
  border: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
  padding: 15px;
}
.card-stats.card .card-body .row {
  border-bottom: 1px solid #d6ddee;
  margin-bottom: 20px;
}
.card-stats.card .card-body .row .card-category {
  font-size: 14px;
  margin-bottom: 10px;
}

.dashboard-graph .card {
  border: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
}
.dashboard-graph .card .card-body {
  width: 100%;
  overflow-x: auto;
}
.dashboard-graph .card .card-body .card-title {
  text-align: center;
  font-weight: bolder !important;
  color: #522f2f;
}
/**
I commented this out for you to review
*/


#ant-table {
  border-radius: 10px !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
  background-color: white;
}


.ant-table-wrapper .ant-spin-container .ant-table-cell {
  text-align: center;
  font-size: 14px;
}
.ant-table-wrapper .ant-spin-container .ant-table-row:nth-child(odd) {
  background-color: #f3f5fa;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab,
.ant-tabs-tab-active,
.ant-tabs-tab:hover {
  border-bottom-color: #2cb34a !important;
  color: #2cb34a;
}

.ant-tabs-tab:hover {
  border-bottom-color: #2cb34a !important;
  font-weight: 700 !important;
  color: #2cb34a;
}

/**
I commented this out for you to review
*/


#ant-table tr th:first-child {
  border-radius: 10px 0 0 0;
}



.ant-table-wrapper .ant-spin-container .ant-table-thead tr th:last-child {
  border-radius: 0 10px 0 0;
}

.PENDING {
  background-color: rgba(238, 137, 36, 0.25) !important;
  color: #ee8924 !important;
  white-space: nowrap;
}

.REJECTED {
  background-color: rgba(240, 28, 29, 0.25) !important;
  color: #f01c1d !important;
  white-space: nowrap;
}

.APPROVED {
  background-color: rgba(59, 184, 77, 0.25) !important;
  color: #3bb84d !important;
  white-space: nowrap;
}

.ant-pagination {
  padding: 15px;
}

.ant-picker {
  display: block !important;
  width: 100% !important;
  height: 40px;
  text-align: center;
  padding: 0;
}
.ant-picker .ant-picker-input {
  width: 100%;
  height: 100%;
  padding: 5px 10px;
}
.ant-picker .ant-picker-input input {
  text-align: center;
}

.ant-select {
  width: 100% !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;
}
.ant-select .ant-select-selector {
  height: 100%;
  padding-top: 5px !important;
}

.input-search {
  background-color: #eee !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.ant-card {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
}

.form-label {
  font-weight: bold !important;
  font-size: 12px !important;
}

.modal-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
}

@media only screen and (max-width: 768px) {
  .page-bg {
    padding-left: 0 !important;
    padding-top: 50px;
  }

  .navbar.enhanced {
    left: 0;
  }

  .toggle-sidebar {
    display: flex;
  }

  .header-search {
    display: none !important;
  }

  .font-small {
    font-size: 12px;
  }

  .font-medium {
    font-size: 14px;
  }

  .font-large {
    font-size: -6px !important;
  }

  .side-nav {
    margin-left: -300px !important;
  }
  .side-nav .side-nav-item {
    padding-left: 10px !important;
  }
}

/*# sourceMappingURL=enhancements.css.map */
