.side-nav {
  min-height: 100vh;
  box-shadow: 1px 0 10px 0 rgba(0, 0, 0, 0.03), 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}

.side-nav-header {
  height: 4rem;
  width: 100%;
  margin-bottom: 1.5rem;
}

.side-nav-header img {
 object-fit: contain;
 height: 100%;
}
