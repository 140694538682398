th {
    background: #ffffff;
    color: #000;
    font-weight: 500;
    height: 60px;
    /*background: #F8FAFB;*/
}

th, thead {
    height: 60px;
    font-size: 14px;
    font-weight: 600;

    border-bottom: 1px #ececec solid;
}

tr {
    /* height: 77px; */
    transition: all .3s, height 0s;
    background-color: #ffffff;
    border-bottom: 1px #ececec solid!important;
}

thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;

}


td {
border-bottom: 1px solid #ececec;
    color: #111111;
}

tr:last-child td{
    border-bottom: none;
}

td, th {
    padding: 16px 16px;
    overflow-wrap: break-word;
    /*vertical-align: top;*/
}

/* tr:hover {
    background-color: #eff7eb;
    border-bottom: 1px solid;
} */

table {
    /* border: 0.5px solid #DFE2E6; */
    /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1); */
    border-radius: 4px;
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0 !important;
}

tr > td {
    padding: 16px 16px;
    overflow-wrap: break-word;
}

.table-wrapper {
    zoom: 1;

    overflow-x: scroll;
  
}

.shadow{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.t-table {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    position: relative;
    clear: both;
}


.table-image-holder{
    /*border: 1px #ececec solid;*/
    /* width: 150px; */
    padding: 1rem 0;
    height: 100px;
    padding: 0 auto;
}

.table-image{
    background-size: contain;
    background-repeat: no-repeat;
}

.seller{
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #868686;
}

.farmer-cart .ant-table-thead > tr > th {
    background-color: white !important;
    color: black !important;
}

