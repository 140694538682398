.id-product-details {
    height: 100%;
    width: 100%;
}

.id-product-details .main-image-wrapper {
    width: 466px;
    height: 436px;
    padding-left: 15%;
}
