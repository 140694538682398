.roles-select {
    max-width: 25%;
    margin-bottom: 30px;
}

/* .ant-select {
  border: 1px solid #dbdbdb;
    border-radius: 7px;
    padding: 15px;
    width: 100%;
} */

.roles-heading {
    margin-top: 40px;
    margin-bottom: 25px;
    color: #2cb34a;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
}

#add-role:hover {
    color: white !important;
}

.col-form-label {
    color: #949790;
}

.add-branch-btn {
  width: 193px;
  height: 48px;
  border-radius: 3px;
  text-align: center;
  color: #ffffff;
}

.btn {
    float: right;
    width: 150px;
    height: 52px;
    margin: 135px 7px 0 46.1px;
    padding: 16px 46px 16px 45px;
    border: 3px rgba(120, 137, 149, 0.26);
    box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.26);
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.addBranch-action-buttons-cancel {
    background-color: #94979b;
}

.addBranch-action-buttons-save {
    background-color: #2cb34a;
}

.ant-select-selection-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select-selection-search {
  display: flex;
  align-items: center;
}
.add-branch .ant-select-selection-item  {
  height: 48px;
}
.agrovets-filter .ant-select-selection-item  {
  height: 50px;
  top: 0 !important;
}

.addBranch-cardbody .ant-select-selection-item {
  height: 50px;
}

.addBranch-cardbody .ant-select-selector {
  /* padding-top: 0 !important; */
}

.add-branch .ant-select-selection-search-input {
  height: 100% !important;
}

.form-check {
    margin-bottom: 30px;
    color: #949790;
}


table {
  table-layout: fixed ;
  width: 100% ;
}
td {
  width: auto;
  vertical-align: middle;
}

.ant-table-thead > tr > th{
    color: #ffffff;
    background-color: #39b54a;
    height: 50px
}

.ant-list-item-meta-title {
  font-size: 18px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff !important;
    border: 1px solid #dbdbdb;
    /* border: 1px solid #39b54a; */
    border-radius: 4px;
    transition: all 0.3s;
    height: 50px;
    margin-top: 15px
}

.mr-15{
    margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mr-5 {
  margin-right: 15px;
}

.ml-5 {
  margin-left: 15px;
}
.ant-select-selection-item{
    line-height: 40px;
}

.overflow-scroll{
    overflow: scroll;
}


.products-heading {
  margin-top: 40px;
  margin-bottom: 25px;
  color: #2cb34a;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
}

.new-sales-form-label {
  font-weight: bolder;
  text-transform: uppercase;
}

.new-sales-form-group {
  margin-top: 45px;
}

.add-sales-btn {
  margin-left: 20px;
}

.btn {
    background-color: transparent;
    color: black;
    border: 1px solid #2cb34a;
}

#order-details-text {
    display: inline;
    color: #2cb34a !important;
}

.ant-card-meta, .order-row {
    margin-top: 2%
}

.product-items-thead {
    border-style: none !important;
    border-bottom-width: 0ch;
}


.ant-picker {
    margin-right: 10px;
    border-color: #2cb34a;
}

.branch-export {
  width: 120px !important;
  margin-right: 15px;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  cursor: pointer;
  background-color: white;
  color: black
}


.pending, .PENDING {
  background-color: #ee8923;
  color: white;
  border-radius: 4px;
  text-transform: capitalize;
  padding: 0 5px;
  text-align: center;
}

.rejected, .REJECTED, .brch-Inactive, .brch-inactive {
  background-color: #F01D1D;
  color: white;
  border-radius: 4px;
  text-transform: capitalize;
  padding: 0 5px;
  text-align: center;
}

.orderDetailsTotal {
  margin-left: 10px;
  height: 20px;
  font-size: 15px;
  font-weight: bolder !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.41px;
}

.orderImageName {
  display: flex;
  flex-direction: row;
  width: 100%
}


.orderProductName {
  margin-top: 5% !important;
  vertical-align: middle;
  padding-left: 15px
}


.orderDetailsTotalText {
  width: 112px;
  height: 20px;
  margin: 0 40px 2px 0;
  font-family: AvenirNext;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.41px;
  color: #818283;
}

.approved, .APPROVED, .brch-active, .brch-Active {
  background-color: #3BB84D;
  color: white;
  border-radius: 4px;
  padding: 0 5px;
  text-transform: capitalize;
  text-align: center;
}

.totalOrder {
  margin-top: 2%;
  display: flex;
  float: right;
}

.ant-select-selector, .ant-picker {
  width: 210px;
}

.btn {
  width: 95px;
}

.order-detail-label{
    width: 112px;
    display: inline;
    height: 14px;
    margin: 0 48px 5px 0px;
    font-family: Rubik;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.13px;
    color: #9fa1a3;
}

.new-sales-action-buttons-cancel {
  background-color: #94979b;
}

.new-sales-action-buttons-create {
  background-color: #2cb34a;
}

.agrovets .page-title-holder  { align-items: center;}

.branch-details .ant-list-item-meta-content {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .order-row {
    margin-top: 1.3rem;
  }

  .stock-request-card .ant-list-item-meta-title {
    font-size: 17px;
  }

  .order-detail-label {
    margin-right: 0;
  }
}

.agrovets-filter .ant-select-selector {
  display: flex;
  align-items: center;
}

.agrovet-details-select .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.farmer-details-con .ant-list-item-meta-content, .collection-center-container .ant-list-item-meta-content {
  height: 40px !important;
  display: flex !important;
  align-items: center !important; 
}

@media only screen and (max-width: 500px) {
  .transporter-details .fs-20 {
      font-size: 15px !important;
  } 

  .transporter-details .delivery-approval-btn span {
      font-size: 10.5px !important;
  }

  .transporter-details .delivery-approval-btn {
      width: 120px !important;
  }

  .transporter-details .ant-list-item-meta-title {
    margin-bottom: 0 !important;
  }

  .transport-milk-details .ant-select-selector, .milk-collection-detail .ant-select-selector {
    height: 40px !important;
    margin-top: 0 !important;
  }

  .transport-milk-details .ant-select-arrow {
    top: 35% !important;
  }

  .transport-milk-details .date-filter {
    margin-left: 15px !important;
  }

  .transport-milk-details .clear-filter {
    padding-top: 0 !important;
  }

  .transport-milk-details .fs-20, .milk-delivery-approvals .fs-20 {
    font-size: 18px !important;
  }

  .milk-delivery-approvals .cooperative-table {
    margin-top: .7rem !important;
  }

  .transporter-details .btn-container {
    margin-top: 1.7rem !important;
  }

  .delivery-approval-btn-container {
    width: 125px !important;
  }

  .delivery-approv-pos {
    right: -5px !important;
  }

  .transport-section-details .ant-list-item-meta-content {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .collection-centre-list .page-title-holder {
    flex-direction: column !important;
    align-items: flex-start !important;
  } 

  .collection-centre-btn-con {
    margin-left: 20.5rem !important;
  } 

  .collection-centre-list .branch-export, .collection-centre-list .print-btn {
    width: 93px !important;
  }

  .collection-centre-list .coll-center-btn {
    width: 138px !important;
    
  }

  .collection-centre-list .coll-center-btn a{
    font-size: 12px !important;
  }

  .farmer-details .border-btn {
    width: 120px !important;
  }

  .farmer-details .page-title-holder {
    height: 40px !important;
  }

  .farmer-details-con .ant-list-item-meta-title, .collection-center-container .ant-list-item-meta-title{
    margin-bottom: 0 !important;
  }

  .collection-center-users .fs-20 {
    font-size: 17px;
  }
  .collection-center-users .page-title-holder {
    margin-top: 0 !important;
  }

  .milk-collection-detail {
    margin-top: 0 !important;
  }

  .milk-collection-detail .date-filter, .milk-collection-detail .status-filter {
    align-items: center;
    margin-bottom: 0 !important;
  }

  .milk-collection-detail .date-filter {
    margin-left: .8rem !important;
  }

  .milk-collection-detail .status-filter .ant-select-selector {
    margin-top: 0 !important;
  }

  .milk-collection-detail .ant-select-selection-item, .transport-milk-details .ant-select-selection-item {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .milk-collection-detail .status-filter .ant-select-arrow {
    top: 35% !important;
  }

  .milk-collection-detail .pagination-wrapper {
    padding: 40.6px 0 !important;
  }

  .collection-center-container .fs-20 {
    font-size: 15px !important;
  }

  .add-milk-delivery-con {
    width: 120px !important;
  }
  .add-milk-del-btn {
    width: 97px !important;
  }

  .add-milk-del-btn  span {
    font-size: 13px !important;
  }

  .add-milk-del-custom {
    padding: 0px !important
  }

  .collection-center-container .ant-list-item-meta-title {
    font-size: 15px !important;
  }

  .agrovets-filter {
    top: 25px !important;
  }
  .agrovets .ant-table-content {
    margin-top: 20px;
  }
  .agrovets .page-title-holder {
    height: 45px;
    align-items: center;
    display: flex;
  }

  .addBranch-cardbody .card-body {
    padding: 1rem .1rem;
  }
  .categories-padding.form-check {
    margin-bottom: 5px !important;
  }

  .collection-center-no-users-img .ant-image, .branch-details .ant-image {
    width: 200px !important;
  }
  .coll-centre-no-user-txt {
    font-size: 17px !important;
  }

  .delivery-approval-btn-container {
    padding: 1.3rem 2rem !important;
  }

  .branch-user .fs-20 {
    font-size: 16px;
  }

  .col-wrap {
    line-break: anywhere;
  }

  .add-branch .addBranch-cardbody {
    padding: .7rem !important;
  }
}

@media screen and (max-width: 450px) {
  .branch-list .page-title-holder {
    flex-direction: column;
    align-items: flex-start;
  }

  .branch-list-btns {
    margin-left: 18.5rem !important;
  }

  .branch-list-btns .border-btn {
    width: 98px !important;
  }
}