/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .m-display {
    display: none;
  }

  .header-logo {
    /* width: inherit; */
    height: 70px;
  }

  .li-style.kyc-details {
    font-size: 11.3px;
  }

  .m-fs-10{
    font-size: 10px;
  }

.banner-style{
  height: inherit;
}

.m-flex-column{
  flex-direction: column;
}

  .p-5 {
    padding: 1.5rem !important;
  }
  .products {
    display: flex;
    /* grid-template-columns: 31% 31% 31%; */
    /* grid-gap: 30px; */
    flex-direction: column;
  }

  .category-grid {
    display: grid;
    grid-template: inherit;
  }

  .product-search-holder {
    width: 100%;
    height: 62px;
    /* margin: 0 10px 1px 0; */
    /* padding: 16px 0px 0px 0px;
    
    align-items: center; */
    display: flex;
    border-radius: 8.5px;
    display: flex;
    background-color: #ffffff;
  }

  .product-search-btn {
    width: 100%;
    margin: 5px 0;
    height: 62px;
    /* margin: 0 0 1px 10px; */
    padding: 17px 9px 18px;
    border-radius: 8.5px;
    background-color: #ffffff;
    border: none;
  }

  .m-flex-column {
    flex-direction: column;
  }

  .f-align-left {
    text-align: center;
  }

  .grid-auto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .li-display{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
  }

  .wrapper {
    width: 25%;
    align-items: center;
    display: flex;
  }
  .table-wrapper {
    overflow-x: scroll;
  }

  .m-space-between {
    justify-content: space-between;
  }

  .m-align-center {
    align-items: center;
  }
  .dma-header-holder {
    height: 85px;
    padding: 15px;
  }

  .banner-style {
    height: 100%;
  }

  .grid-height {
    height: 300px;
  }
  .product-card-holder {
    margin-bottom: 5%;
  }

  .sub-title {
    font-size: 20px;
    width: 100%;
    margin-top: 5px !important;
    margin-bottom: 1.3rem !important;
  }

  .li-style {
    height: 40px;
    line-height: 40px;
  }

  .brands-grid {
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    justify-content: center;
    align-items: center;
  }

  .d-footer {
    width: 100%;
    min-height: 72px;
    flex-direction: column;
    padding: 5%;
    background-color: #088040;
    bottom: 0;
    color: #ffffff;
    /* margin: 0; */
  }

  .footer-grid-auto {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    grid-template-columns: none;
  }
  .category-text {
    font-size: 14px;
  }

  .grid-item1.grid-item2.grid-item3 {
    padding: 15px;
  }

  .show-mobile {
    display: flex;
  }

  ul {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
    text-align: center;
    margin-top: 20px;
  }

  .m-mt-4 {
    margin-top: 2rem;
  }

  .product-card-holder {
    margin-bottom: 7%;
  }

  .cart-padding-right {
    margin-right: 2rem;
  }
  .charge-farmer-container {
    margin-right: 1rem;
  }

  .otp-resend-container {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-drawer-body .fullheight {
    height: 75% !important;
  }

  .profileButton, .profileButton ~ div {
    margin-left: 0px;
  }

  .products-list.pagination-wrapper {
    padding: 10.6px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .m-display {
    display: none;
  }

  .m-flex-column{
    flex-direction: column;
  }

  .otp-input{
    height: 50px;
    width: 31px;
    /* border-radius: 50%; */
    border-style: none;
    border-bottom: #D8D8D8 solid 1px;
    text-align: center;
    outline: none!important;
    font-size: 20px;
    margin-right: 15px;
  }

  
.banner-style{
  height: inherit;
}

  .category-grid {
    display: grid;
    grid-template: inherit;
}

  .li-style {
    height: 40px;
    line-height: 40px;
  }
  .wrapper {
    align-items: center;
  }

  ul {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
    text-align: center;
    margin-top: 20px;
  }
  .products {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 30px;
    flex-direction: column;
  }

  .d-footer {
    width: 100%;
    min-height: 72px;
    flex-direction: column;
    padding: 5%;
    background-color: #088040;
    bottom: 0;
    color: #ffffff;
    /* margin: 0; */
  }

  .m-space-between {
    justify-content: space-between;
  }

  .m-align-center {
    align-items: center;
  }

  .tablet-view {
    width: 100%;
  }

  .m-row {
    display: flex;
    flex-direction: column;
  }

  .header-logo {
    /* width: 50px; */
    height: 40px;
  }

  .grid-height {
    height: 300px;
  }
  .product-card-holder {
    margin-bottom: 5%;
  }

  .sub-title {
    font-size: 20px;
  }

  .show-mobile {
    display: flex;
  }

  .m-display-none {
    display: none;
  }
  .grid-auto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .li-display{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }


  .m-mt-4 {
    margin-top: 2rem;
  }

  
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .header-logo {
    /* width: 70px; */
    height: 52px;
  }
  .m-flex-column{
    flex-direction: row;
  }


  .d-footer {
    width: 100%;
    min-height: 72px;
    flex-direction: row;
    padding: 5%;
    background-color: #088040;
    bottom: 0;
    color: #ffffff;
    /* margin: 0; */
  }

  

  .banner-style{
    height: inherit;
  }

  .m-mt-4 {
    margin-top: 2rem;
  }
  .m-display-none {
    display: none;
  }

  .m-align-center {
    align-items: center;
  }

  .wrapper {
    align-items: center;
  }

  .grid-auto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .li-display{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  ul {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
    text-align: center;
    margin-top: 20px;
  }
  .li-style {
    height: 40px;
    line-height: 40px;
  }

  .show-mobile {
    display: flex;
  }


  .product-search-btn {
    width: 118px;
    height: 62px;
    margin: 0 0 1px 10px;
    padding: 17px 9px 18px;
    border-radius: 8.5px;
    background-color: #ffffff;
    border: none;
  }

  .product-search-holder {
    width: 851px;
    height: 62px;
    margin: 0 10px 1px 0;
    /* padding: 16px 0px 0px 0px; */
    display: flex;
    align-items: center;
    border-radius: 8.5px;
    background-color: #ffffff;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .header-logo {
    /* width: 150px; */
    height: 80px;
  }

  .banner-style {
    height: calc(100vh - 103px);
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.08);
  }
  
  .sub-title {
    font-size: 32px;
}


.d-footer {
  width: 100%;
  min-height: 72px;
  flex-direction: row;
  padding: 14px;
  background-color: #088040;
  bottom: 0;
  color: #ffffff;
  /* margin: 0; */
}

.footer-grid-auto ul{
  margin-top: 0;
}


.category-grid {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 50% 25% 25%;
  grid-gap: 15px;
}

  .m-mt-4 {
    margin-top: 0;
  }
  .show-mobile {
    display: none;
  }

  .m-row {
    display: flex;
    flex-direction: row;
  }

  .products {
    display: grid;
    grid-template-columns: 31% 31% 31%;
    grid-gap: 30px;
  }

  .product-search-btn {
    width: 118px;
    height: 62px;
    margin: 0 0 1px 10px;
    padding: 17px 9px 18px;
    border-radius: 8.5px;
    background-color: #ffffff;
    border: none;
  }

  .product-search-holder {
    width: 851px;
    height: 62px;
    margin: 0 10px 1px 0;
    /* padding: 16px 222px 18px 14px; */
    border-radius: 8.5px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
  }
  .grid-auto {
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: center;
  }
  .li-display{
    display: flex;
    gap: 20px;
    flex-direction: row;
  }
  .m-display-none {
    display: block;
  }

  .m-flex-column{
    flex-direction: row;
  }
  
}

@media only screen and (min-width: 993px) and (max-width: 1367px) {
  .nav-item-long-text {
    line-height: 18px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .m-mt-4 {
    margin-top: 0;
  }

  .product-card-holder {
    height: 450px
  }
  .banner-style {
    height: calc(100vh - 103px);
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.08);
    margin-top: 7rem;
  }
  
  .products {
    display: grid;
    grid-template-columns: 31% 31% 31%;
    grid-gap: 30px;
  }
  .m-row {
    display: flex;
    flex-direction: row;
  }

  .grid-auto {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    align-items: center;
  }
  .li-display{
    display: flex;
    gap: 20px;
    flex-direction: row;
  }

.category-grid {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 50% 25% 25%;
  grid-gap: 15px;
  /* height: 631px; */
}

  .product-search-btn {
    width: 118px;
    height: 62px;
    margin: 0 0 1px 10px;
    padding: 17px 9px 18px;
    border-radius: 8.5px;
    background-color: #ffffff;
    border: none;
  }

  .coop-stock-search-btn {
    width: 170px;
    height: 62px;
    margin: 0 0 1px 10px;
    padding: 17px 9px 18px;
    border-radius: 8.5px;
    background-color: #ffffff;
    border: none;
  }

  .product-search-holder {
    width: 851px;
    height: 62px;
    margin: 0 10px 1px 0;
    display: flex;

    align-items: center;
    /* padding: 16px 222px 18px 14px; */
    border-radius: 8.5px;
    background-color: #ffffff;
  }

  .m-flex-column{
    flex-direction: row;
  }
  

  .m-display-none {
    display: block;
  }
}

@media screen and (max-width: 450px) {
  .branch-txt, .agrovet-txt {
    margin-left: 0 !important;
  }

  .header-divider.ant-divider {
    display: none !important;
  }

  .sidebar-container {
    margin-top: 70px !important;
  }
  .ant-drawer-body .fullheight {
    height: 73% !important;
  }
}

@media only screen and (max-width: 376px) {
  .ant-drawer-body .fullheight {
      height: 85% !important;
  }

  .li-display {
    gap: 4px;
  }
}

@media only screen and (max-width: 285px) {
  .ant-drawer-body .fullheight {
    height: 85% !important;
  }
}
