.side-nav-item {
  font-size: 1rem;
}

.side-nav-item-link {
  color: black;
  display: block;
  padding-left: 1.8rem;
  text-decoration: none;
}

.sidenav-child {
  padding-left: 20% !important;
  margin-bottom: 10px;
  width: auto;
}

.disabledSideNav {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.side-nav-item-link.selected {
  background-color: #2cb34a;
  color: #fff;
  padding-top: .1rem;
  padding-bottom: .1rem;
}
