.error-template {
    padding: 40px 15px;
    text-align: center;
}
.error-actions {
    margin-top:15px;
    margin-bottom:15px;
    text-align: center;
}
.error-actions .btn { 
    margin-right:10px; 
}

.error-back-btn {
    background: #2cb34a 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #ffffff;
    text-align: center;
    border: none;
    height: 48px;
    width: inherit;
    line-height: 48px;
  }