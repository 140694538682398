ul.li-text{
  margin-top: 0;
}

ul.grid-auto{
  margin-top: 0;
}

ul.li-display{
  margin-top: 0;
}

.dma-header-holder {
  width: 100%;
  height: 80px;
  background-color: #ffffff;
}

.dma-header-position {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 10%);
}

.li-text {
  size: 13px;
  color: #222222;
  font-weight: 600;
}

.nav-ellipsis{
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.li-style {
  list-style-type: none;
  text-align: left;
}

.li-display{
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.margin-auto{
  margin: auto;
}

.grid-auto {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
}

.banner-style {
  height: calc(100vh - 103px);
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.08);
}

.ant-slider-mark {
  padding: 10px;
}

.category-grid {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 50% 25% 25%;
  grid-gap: 15px;
  /* height: 631px; */
}

.sub-category-grid {
  display: grid;
  grid-gap: 30px;
  grid-template-rows: 50% 25% 25%;
  /* grid-template-columns: auto auto; */
}

.grid-item1 {
  border-radius: 16px;
  width: 100%;
  padding: 5%;

  background-color: #ffffff;
  background-size: contain;
  /* grid-column: 1 ; */
  /* grid-row: 2; */
}

.grid-height {
  height: 631px;
}

.category-text {
  font-size: 40px;
  letter-spacing: 0.47px;
  color: #00b63e;
  font-weight: 500;
}

.grid-item4 {
  border-radius: 16px;
  width: 100%;
  padding: 5%;
  background-size: cover;
  background-size: contain;
  background-color: #f3f4ef;
}

.grid-item2 {
  background-color: #ffffff;
  padding: 11px 37px 11px 32px;
  background-size: contain;
  background-color: #f3f4ef;
}

.grid-container {
  display: grid;
  grid-gap: 30px;
  background-color: #2196f3;
  padding: 10px;
}


.grid-item3 {
  padding: 11px 37px 11px 32px;
  background-color: #ffffff;
  background-size: contain;
}

.grid-item5 {
  background-color: rgba(233, 153, 42, 0.79);
  padding: 11px 37px 11px 32px;
  background-size: contain;
}

.grid-item6 {
  padding: 11px 37px 11px 32px;
  background-size: contain;
  background-color: #ffffff;
}
.sub-title {
  /* width: 442px; */
  height: 28px;
  /* font-family: AvenirNext; */
  font-size: 38px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: normal;
  color: #2cb34a;
}

.product-image-card {
  height: 200px;
  border-radius: 16px;
}

.product-card-holder {
  padding: 0 0 25px;
  border-radius: 16px;
  /* height: 420px; */
  background-color: #ffffff;
}

.product-title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #000000;
  padding: 2% 8%;
}
.product-title-font{
  font-size: 26px;
}

.in__stock{
  width: 74px;
  height: 24px;
  padding: 0 7.6px;
  font-family: Avenir-Roman;
  border-radius: 4px;
  background-color: rgba(59, 184, 77, 0.4);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #3bb84d;
}


.out__of__stock{
  width: 74px;
  height: 24px;
  border-radius: 4px;
  padding: 0 7.4px 0 7.6px;
  font-family: Avenir-Roman;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;

  letter-spacing: normal;
  text-align: center;
  color: #b0b1b0;
  background-color: rgba(182, 185, 183, 0.4);
}

.low__stock{
  width: 74px;
  height: 24px;
  padding: 0 7.4px 0 7.6px;
  opacity: 0.8;
  border-radius: 4px;
  background-color: rgba(239, 55, 62, 0.4);
  font-family: Avenir-Roman;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #ef373e;
}


.pt-5 {
  padding-top: 5px;
}

.product-description {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #8e8e93;
  padding: 2% 8%;
}

.product-currency {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  color: #2cb34a;
  /* padding: 8%  */
}

.brands-grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 40px;
}

.dashboard-orders{
  height: 500px;
  overflow: scroll;
}

.brand-img-height {
  max-height: 109px;
}

.brand-grid-item {
  width: 226px;
  height: 109px;
  display: flex;
  align-items: center;
  /* margin: 25px 28px 6px; */
  justify-content: center;
  background-color: #ffffff;
  border-radius: 3px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  padding-bottom: 30px;
}

.category-holder {
  min-height: 200px;
}

.profile-img-wrapper {
  width: 37px;
  height: 41px;
  margin: 0 16px 0 0;
  border: solid 1px #000000;
  background-color: black;
  border-radius: 100%;
}

.pb-5 {
  padding-bottom: 3rem;
}

.ant-badge-count {
  background-color: #f6ae3f;
  color: #222222;
  font-weight: 600;
}

.subtext-pc {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #39b54a;
}

.farmer-subtitle {
  margin: 24px 0;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.44px;
  text-align: center;
}

.search-farmer-btn {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
}

.farmer-image-holder {
  width: 180px;
  height: 180px;
  border: solid 1px #2cb34a;
  border-radius: 50%;
}

.farmer-name {
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.farmer-id {
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.89px;
  color: #919396;
}

.farmer-name-abbr {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  background-color: #2cb34a;
  color: #ffffff;
}

.no-farmer-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: #f01d1d;
}

.no-farmer-sub-text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  color: #94979b;
}

.cancel-btn {
  width: 193px;
  height: 48px;
  border-radius: 3px;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.26);
  border: solid 1px #94979b;
  background-color: #94979b;
  text-align: center;
  color: #ffffff;
}

.min-height-400px {
  min-height: 400px;
}

.order-name-abbr {
  width: 48px;
  height: 48px;
  /* margin: 0 21px 0 0; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b8b7bd;
}

.fs-17 {
  font-size: 17px;
}

.verify__user__holder{
  padding: 1rem;
  background-color: #f3c1a6;
  color: #ea754b;
}

.verify__btn{
  background-color:  #2cb34a;
  color: #fff;
  padding: 0.25rem 2rem;
  margin-left: 2rem;
  font-weight: 600;
}

.order-number {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #9fa1a3;
}

.f-align-left {
  text-align: left;
}


