.idp-nav {
  height: 4rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}

.ant-dropdown-menu-item-active {
  background-color: #2cb34a !important;
  color: white
}

.main-heading {
  left: 0;
}