@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/AvenirNextLTPro-Regular.otf'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
    /* Modern Browsers */ url('./fonts/AvenirNextLTPro-Regular.otf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/AvenirNextLTPro-Regular.otf')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Avenir-bold';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/AvenirNextLTPro-Bold.otf'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
    /* Modern Browsers */ url('./fonts/AvenirNextLTPro-Bold.otf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/AvenirNextLTPro-Bold.otf')
      format('svg'); /* Legacy iOS */
}

.orderTable {
  width: 100% !important;
  overflow-x: scroll;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
}


.mpesaCardCheckout {
  width: 100%;
}

.orderTable {
  padding-left: 0%;
}

.orderTable > thead {
  width: 100%;
}

.orderTable > tbody {
  width: 100%;
}

.ant-select-arrow {
  margin-top: 0 !important;
}

.ant-select-selection-item {
  top: -5px;
}


@media (max-width: 600px) {

  .farmerOrderListCheck {
    padding-left: 5%;
  }

  .mpesaCardCheckout {
    width: auto;
  }


  /* .order-name-abbr, .orderCancel {
    margin-left: 32%;
  } */

  .orderTable, .orderCard {  
    overflow-x: scroll;
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    white-space: pre-wrap;
  }
}


@media print {
  .do-not-print {
    display: none;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100%;
  }
}

.ant-breadcrumb-link {
  color: #2cb34a;
}

.page-padding-bottom {
  padding-bottom: 100px;
}

.banner-style {
  object-fit: contain;
}

html {
  height: 100vh;
  margin: 0;
  font-family: Avenir;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --antd-wave-shadow-color: #2cb34a;
  background-color: #ffffff;
}


::selection {
  color: #fff;
  background: #2cb34a !important;
}

th {
  font-family: Avenir-bold;
}

.Avenir {
  font-family: Avenir;
}

body {
  margin: 0;
  font-family: Avenir;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primary-color {
  color: #2cb34a;
}

.primary-bg-color {
  background-color: #2cb34a;
}

.primary-border {
  border: 1px solid #2cb34a;
}

.muted-bg-color {
  background-color: #94979b;
}

.fs-20 {
  font-size: 20px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fw-500 {
  font-weight: 500;
  font-family: Avenir-bold;
}

.fw-bold {
  font-weight: bold;
  font-family: Avenir-bold;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.pointer {
  cursor: pointer;
}

.grey-color {
  color: #0000009a;
}

.light-grey-color {
  color: #797a7d;
}

.white-color {
  color: #ffffff;
}

.white-background {
  background-color: #ffffff;
}

.black-color {
  color: #000000;
}
.margin-top-5px {
  margin-top: 15px !important;
}
.ml-2 {
  margin-left: 0.25rem;
}

.fullheight {
  height: 100%;
}

.register-big-header {
  font-size: 36px;
  font-weight: 900;
}

.dma-page-margin {
  margin-top: 80px;
}

.auth-card {
  box-shadow: 0px 1px 4px #00000033;
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 600px;
  padding: 30px;
}

.height-100vh {
  height: 100vh;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.min-height-100vh {
  min-height: 100vh;
}

.min-height-80vh {
  min-height: 80vh;
}

.margin-auto {
  margin: auto;
}

.red-color {
  color: red;
}

.cursor-disabled {
  cursor: not-allowed;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.custom-btn {
  min-width: 132px;
  height: 43px;
  padding: 11px 35px 9px 34px;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #2cb34a;
  border: none;
  color: #ffffff;
}

.outline-none {
  outline: none;
}

.search-bg {
  background-color: #f8f8f8 !important;
  border: #e8e8e8;
  height: 43px;
}

.icon-style {
  background-color: inherit;
  border: none;
  display: flex;
  align-items: center;
  height: 100%;
}

.dma-bg {
  background-color: #f8f9fc;
}

.min-height-100 {
  min-height: 100vh;
}

.height-100 {
  height: 100%;
}

.dma-box-shadow {
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.08);
}

.m-row {
  display: flex;
  flex-direction: row;
}

.d-footer {
  width: 100%;
  min-height: 72px;
  padding: 13px 398px 14px 98px;
  background-color: #088040;
  bottom: 0;
  color: #ffffff;
  margin: 0;
}

.show-mobile {
  display: none;
}

.li-style-none {
  list-style-type: none;
}

.fw-400{
  font-weight: 400!important;
}

.padding-0{
  padding: 0;
}

.footer-grid-auto {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: auto auto auto;
}

.active-status {
  background-color: #c1e9c6;
  color: #2cb34a;
  border-radius: 4px;
  padding: 0 5px;
  text-align: center;
}

.rejected-status {
  background-color: #f01d1d;
  color: #ffffff;
  border-radius: 4px;
  padding: 0 5px;
  text-align: center;
}

.page-padding-bottom {
  /* padding-bottom: 194px; */
  margin-top: 8.5%;
}

.page-title-holder {
  /*min-height: 100px;*/
  align-items: center;
}

.pending,
.Pending,
.PENDING {
  background-color: #ee8923;
  color: white;
  border-radius: 4px;
  text-transform: capitalize;
  padding: 0 5px;
  text-align: center;
}

.width-200px{
  width: 230px;
}

.border-btn{
  width: 230px;
  height: 48px;
  padding: 15px 57px;
  border-radius: 5px;
  border: solid 1px #39b54a;
  color: #39b54a;
  background-color: #ffffff;
  
}

.dma-pending,
.dma-Pending,
.dma-PENDING {
  background-color: #f3c1a6;
  color: #ea754b;
  border-radius: 4px;
  padding: 0 5px;
  text-align: center;
}

.logout {
  color: #ea754b;
}

.profileButton {
  color: #39b54a;
}

.approved,
.Approved,
.APPROVED {
  width: 80px;
  background-color: #3bb84d;
  color: white;
  border-radius: 4px;
  padding: 0 5px;
  text-transform: capitalize;
  text-align: center;
}

.rejected,
.Rejected,
.REJECTED {
  background-color: #f01d1d;
  color: white;
  border-radius: 4px;
  text-transform: capitalize;
  padding: 0 5px;
  text-align: center;
}

.dma-error {
  background-color: #f01d1d;
  color: white;
  border-radius: 4px;
  text-align: center;
  font-size: 17px;
}

.dma-success {
  background-color: #3bb84d;
  color: white;
  border-radius: 4px;
  text-align: center;
  font-size: 17px;
}

.input-search {
  background-color: #f7f7f7;
  border-radius: 8px;
  border: none;
  height: 40px;
  outline: none;
  line-height: 40px;
}

.page-bg {
  background-color: #ffffff;
}

.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 60.6px;
}

.capitalize {
  text-transform: capitalize;
}

.ant-breadcrumb > span:last-child a {
  color: #2cb34a;
  font-size: 18px;
  font-weight: 500;
}

.ant-breadcrumb a {
  color: #828282;
  font-size: 18px;
  font-weight: 500;
}

.ant-breadcrumb a:hover {
  color: #222222;
}

.uppercase {
  text-transform: uppercase;
}

.card-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.card-to-footer-m {
  margin-bottom: 200px;
}

.no-user-icon {
  width: 85px;
  height: 85px;
  background-color: #ff0000;
  border-radius: 50%;
}

.gap-30 {
  gap: 30px;
}

.fw-600 {
  font-weight: 600;
}

.object-fit-contain {
  object-fit: contain;
}

.mr-3 {
  margin-right: 1.5rem;
}

/* input[type=number] {
  -moz-appearance: textfield;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.m-display {
  display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.primary-btn {
  background: #2cb34a 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  border: none;
  height: 48px;
  line-height: 48px;
}

.danger-btn {
  background: #ff0000 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  border: none;
  height: 48px;
  line-height: 48px;
}

.danger-btn-2 {
  background: inherit;
  border: rgb(221, 59, 59) solid 1px;
  border-radius: 5px;
  opacity: 1;
  color: rgb(221, 59, 59);
  text-align: center;
  height: 48px;
  line-height: 48px;
}

.danger-btn-2:hover {
  background: rgb(221, 59, 59) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #ffffff;
  text-align: center;
}

.n-btn-style {
  width: 193px;
  height: 48px;
  margin: 123px 0 0 46px;
  padding: 14px 46px 14px 45px;
  border-radius: 3px;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.26);
  background-color: #2cb34a;
}

.description-ellipses {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipses {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fs-24 {
  font-size: 24px;
}

.radio-wrapper {
  border-radius: 3px;
  min-height: 100px;
  border: #dde3e8 1px solid;
  padding: 1.5rem;
}

.fullwidth {
  width: 100%;
}

.white-bg-btn {
  cursor: pointer;
  background-color: white !important;
  color: black !important;
}

@media only screen and (max-width: 400px) {
  .signInTerms {
    display: none;
  }

  .similar-products.sub-title {
      margin-top: 70px;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  body,
  html {
    min-width: 100% !important;
    height: 100% !important;
  }
  .m-display-none {
    display: none;
  }

  .idp-nav {
    min-width: 100%;
  }

  .page-bg {
    min-width: 100%;
  }

  /* .side-nav {
    display: none;
  } */

  .innerContent-main {
    min-width: 100% !important;
  }

  .innerContent {
    margin: 0;
    width: 100%;
  }

  .card {
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .m-display {
    display: block;
  }

  .farmer-order-filter .ant-select-arrow {
    margin-top: 0 !important;
  }

  .border-btn {
    width: 75px;
    padding: 15px 40px;
    height: 32px;
    font-size: 14px !important;
  }

  .farmer-orders-list.show-mobile {
    margin-top: 1rem;
  }
}

@import url('./enhancements.css');